.nav-btn {
    height: 29.5px;
    padding: 0 1rem;
    font-size: 15px;
    font-weight: 400;
    color: #999898;
    /* border-bottom: 1px solid grey; */
    list-style: none;
    white-space: nowrap;
}

.nav-btn-active {
    height: 27px;
    /* padding: 0 1rem; */
    margin: 0 1rem;
    font-size: 15px;
    font-weight: 400;
    font-weight: bold;
    border-bottom: 4px solid #c6dfab;
    color: #999898;
    list-style: none; 
    white-space: nowrap;
}

/* .nav-btn-active::after {
    content: '';
    display: inline-block;
    margin-left: 0.3rem;
    height: 30px;
    width: 30px;
    border-radius: 1rem;
    background-color: #ffffff;
    color: #0295FF;
} */

span-el {
    display: none;
}

span-el-active {
    display: inline;
    position: relative;
    left: 2rem;
    height: 20px;
    width: 20px;
    border-radius: 1rem;
    background-color: #ffffff;
    color: #0295FF;
}