.avatar {
  width: 105px;
  height: 103px;
  display: flex; 
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
}

/* .avatar img {
  object-fit: cover;
} */