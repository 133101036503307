.result-item {
    height: 105px;
    margin-bottom: 20px;
  }
  
  .result-item__content {
    display: flex;
    justify-content: space-between;
    background-color:#fff;
    height: 105px;
    padding: 0 12px;
  }
  
  .result-item__image {
    height: 105px;   
  }

  .result-item__info {
    width: 100%;
    height: 100%;
    padding: 0 20px; 
    overflow: hidden;
  }

  .result-item-col-info {
    text-align: left;
    height: 70%;
  }

  .result-item-row-info {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .phone-link {
    margin: 0;
  }

  .name {
    margin: 3px 0;
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.02px;
    color: #4f4f4f;
    white-space: nowrap;
 }

 .description {
    margin: 3px 0;
    font-family: Helvetica;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.02px;
    color: #999898;
    white-space: nowrap;
 } 

 .address {
   margin: 3px 0;
   font-family: Helvetica;
   font-size: 15px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: -0.02px;
   color: #999898;
   
 }
 
 .open {
  margin: 0;
  text-align: center;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.02px;
  color: #1fb95b;
 }

 .close {
  margin: 0;
  text-align: center;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.02px;
  color: #f11c1c;
 }

 .distance {
  margin: 0;
  text-align: center;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.02px;
  color: #4f4f4f;
 }


