.splash-container {
  width: 100%;
  height: 100vh;
  margin: 0;
  background-color: #F9FCFD;
}
.system-name {
  font-size: 4rem;
  font-weight: 500;
  margin: 0;
  padding-top: 7rem;
  margin-left: 2rem;
}
.sub-title-system-name {
  font-size: 2rem;
  font-weight: 100;
  margin: 0;
  margin-left: 3rem;
}


.splash-imgs {
  display: flex;
  justify-content: center;
  padding-right: 1.5rem;
  margin-top: 15vh;
  }

  .logo-name {
    font-size: 25px;
    margin-top: 23px;
    /* font-weight: 500; */
    letter-spacing: 2.5px;
    color: rgba(0, 0, 0, 0.76);
    font-family:Helvetica;
  }

  .splash-animation {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top:20px;
  }

  @keyframes run {
    0% {
      left: -90px;
      color: #eee;
    }
    50% {
      color: #666;
    }
    100% {
      left: 90px;
      color: #eee;
    }
  }