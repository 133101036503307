.item-side-drawer {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  color: #000000;
  overflow-y: scroll;
  overflow-x: hidden;
}
