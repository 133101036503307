.hotel-img-wrapper {
    width: 100%;
    height: 250px;
  }

.sticky-header {
  position: sticky;
  top: -195px;
  padding: 0 0rem;
  background-color: #fff;
}  

.hotel-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
 } 

.header-title {
    width: 50%;
    margin: 0;
    margin-top: -15%;
    padding-left: 2%;
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 2.96px;
    color: #fff;
    transition: 0.2;
    text-align: left;
  }

  .nav-wrapper {
    width: 100%;
    height: 50px;
    padding-top: 1.5rem;
    margin: 0;
    background-color: #ffffff;
    display: flex;
    justify-content: flex-start;
    padding-left: 0rem;
    overflow-x: scroll;
  }

  .underline {
    margin: 0 1rem;
    position: relative;
    top: -20px;
  }

  @media screen and (min-width: 568px) {
    .hotel-img-wrapper {
      display: none;
    }
    .hotel-img {
      display: none;
    }
    .header-title{
      display: none;
    }
    .sticky-header{
      width: 100%;
      height: 15%;
      position: fixed;
      top: 0;
      z-index: 7;
      margin: 0;
      padding: 0;
    }
    .nav-wrapper {
      width: 100%;
      height: 15%;
      padding-top: 1rem;
      position: fixed;
      top: 0;
      z-index: 8;
      margin: 0;
      padding-left: 1rem;
      background-color: #ffffff;
      display: flex;
      justify-content: flex-start;
      overflow-x: scroll;
    }
  }
