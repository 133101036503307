.App {
  text-align: center;
}

.i-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.i-app-title {
  text-align: center;
  font-size: 5vw;
}

.i-app-content {
  text-align: left;
  font-size: 4vw;
  margin: 0;
  padding: 16px;
}

.i-app-footer {
  position: absolute;
  bottom: 10px;
  left: 25px;
  font-size: 4vw;
  letter-spacing: normal;
}
