.close-item-drawer-icon {
   text-align: end;
   width: 100%;
   color: #68747e;
   border: none;
   transform: rotate(180deg);
 }

.back-btn {
  text-decoration: none;
}
 
 .iframe-item__wrapper {
   position: sticky;
   top: -25%;
   height: 30vh;
 }

 .iframe-item__content {
   height: 30vh;
   padding: 1rem 1.5rem;
   padding-bottom: 0.3rem;
   padding: 0;
 }

 .item-info-title {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    width: 100%;
    font-family: Helvetica;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.04px;
    color: #ffffff;
    padding-left: 2vw;
    margin-top: -10%;
}

.item-info-title-when-3d {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  font-family: Helvetica;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.04px;
  color: #000;
  padding-left: 2vw;
  margin-top: -10%;
}

.item-info-sub-title {
  width: 100%;
  overflow-wrap: break-word;
  font-size: calc(1vh + .7rem) ; 
  letter-spacing: 3.3px;
  text-align: center;
  margin: 0;
  padding-bottom: 0.5rem ;
}

.h3-description {
  font-size: 1.3rem;
  font-weight: 300;
  letter-spacing: 1.96px;
  padding-left: 1rem;
  margin: 0 0;
}

.item-hash-list {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: start; 
  padding: 15px 15px ;
}

.item-hash {
font-size: 12px;
margin: 15px 5px 15px 0;
color: rgba(90, 163, 12, 0.82);
border: solid 1px #979797;
padding: 2px 5px;
border-radius: 5px;
font-weight: 700;
text-align: center;
}

.place-info {
  width: 92%;
  /* height: 20vh; */
  margin-left: 15px;
}

.item-info-name {
  font-family: Helvetica;
  font-size: 30px;
  letter-spacing: -0.04px;
  color: #4f4f4f;

}

.item-info-discription {
  /* height: 108px; */
  margin: 6px 40px 15px 0px;
  font-family: Helvetica;
  font-size: 15px;
  font-weight: 300;
  color: #999898;
}

.flag-container {
  padding: 0.5rem 0.5rem;
  margin: 1rem 1rem;
  width: 65%;
  background-color: #c6dfab;
  clip-path: polygon(0% 0%, 65% 0%, 100% 100%, 0% 100%);
}

/* .flag-container p {
  animation: flag-container 10s linear infinite;
}

@keyframes flag-container {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
} */

.contact {
  font-family: Helvetica;
  display: flex;
  align-items: center;
  font-size: 15px;
  letter-spacing: -0.02px;
  color: #4f4f4f;
  margin: 0;
  padding-left: 0.5rem;
  width: 65%;
  white-space: nowrap;
  overflow: hidden;
}

.map-container {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: left;
  padding: 1rem 0;
  margin-left: 15px;
}

.map-container > iframe {
  width: 90%;
  height: 150px;
  border: 2px solid #000;
}





