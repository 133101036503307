.items-info-list{
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 80%;
   margin: 15px 19px;
   padding: 1rem 0;
}

.item-info-link {
   display: flex;
   align-items: center;
  }

.item-info {
   font-size: 1.1rem;
   font-weight: 300;
   letter-spacing: 1.90px;
   margin-left: 1rem;
   margin-top: 0.2rem;
   margin-bottom: 0;
   display: flex;
   align-items: center;
   word-break: break-word;
 }

 .item-info > a {
   text-decoration: none;
 }


  







