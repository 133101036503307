.modal-3d-header {
    width: 100%;
    height: 65px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
}

.close-3d-modal-btn {
    width: 10%;
    background-color: #ffffff;
    border: none;
    font-size: 30px;
    padding: 0;
    margin-left: 1rem;
}

.modal-3d-header-phone {
    width: 10%;
    text-decoration: none;
    color: black;
    text-align: center;
}

.modal-3d-header-address {
    width: 10%;
    text-align: center;
    margin-right: 1rem;
}
