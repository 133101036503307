.item-iframe {
    width: 100%;
    height: 100%;
    display: flex; 
    justify-content: center;
    align-items: center;
  }
  
  .item-iframe iframe {
    display: block;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
    border-radius: 0.2rem;
  }