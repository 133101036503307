.footer-bg {
  width: 100%;
  height: 12vh;
  position: fixed;
  bottom: 0;
  background-color: #fff;
}

.footer-container {
    width: 90%;
    height: 12vh;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    bottom: 0;
    background-color: #fff;
    z-index: 10;
    border-top: 1px solid #979797;
    margin: 0 1rem;  
}

.footer-item-wrapper {
  width: 33%;
  text-align: center;
}

.footer-item {
    color: #000;
    width: 26px;
    height: 26px;
    text-decoration: none;
}

.walk > .text{
   margin-top: 0;
   font-family: Helvetica;
   font-size: 15px;
   font-weight: normal;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: -0.02px;
   color: #4f4f4f;
}

.texi > .text{
  margin-top: 6px;
  font-family: Helvetica;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.02px;
  color: #4f4f4f;
}

.consierge > .text{
  margin-top: 0;
  font-family: Helvetica;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.02px;
  color: #4f4f4f;
}

@media screen and (min-width: 568px) {
  .footer-container {
    display: none; 
  }
}