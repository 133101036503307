.result-list-normal{
    width: 100%;
    /* overflow: scroll; */
    list-style: none;
    margin-top: 1rem;
    margin-bottom: 15vh;
    padding: 0;
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}
 
  .no-found-msg-container {
    position: absolute;
    top: 40vh;
  }

  .item-link-style {
    text-decoration: none;
  }

  @media screen and (min-width: 568px) {
    .result-list-normal{
      position: absolute;
      top: 20%;
      z-index: 5;
      width: 100%;
      overflow: scroll;
      list-style: none;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
    }
  }
